import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  MainWrapper,
  MapWrapper,
  TitleWrapper,
  Title,
  List,
  ListItemWrapper,
  ListItem,
  DescriptionWrapper,
  Column,
  Description,
  DescriptionTitle,
  HorizontalDivider,
} from "./style";
import Loader from "../../../../../common/Loader";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

const HistoryMap = React.lazy(() => import("../Map"));

interface HistoryProps {
  pageChange: PageChange;
}

const HistoryMobile: React.FC<HistoryProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");

  return (
    <>
      <PageTitle subtitle={t(`history_title`)} />

      <PageWrapper animation={pageChange}>
        <MainWrapper>
          <MapWrapper>
            <Suspense fallback={<Loader width={120} height={120} />}>
              <HistoryMap />
            </Suspense>
          </MapWrapper>
          <TitleWrapper>
            {t(`history_firstTitle`)
              .split("\n")
              .map((text, index) => (
                <Title color={index === 0 ? "#151515" : "#0500FF"} key={index}>
                  {text}
                </Title>
              ))}
          </TitleWrapper>
          <TitleWrapper>
            {t(`history_secondTitle`)
              .split("\n")
              .map((text, index) => (
                <Title color={index === 0 ? "#151515" : "#0500FF"} key={index}>
                  {text}
                </Title>
              ))}
          </TitleWrapper>
          <List>
            <tbody>
              <ListItemWrapper>
                <ListItem>{t(`transactionSystems`)}</ListItem>
              </ListItemWrapper>
              <ListItemWrapper>
                <ListItem>{t(`provisioningSystems`)}</ListItem>
              </ListItemWrapper>
              <ListItemWrapper>
                <ListItem>{t(`hybridTV`)}</ListItem>
              </ListItemWrapper>
              <ListItemWrapper>
                <ListItem>{t(`mobileApps`)}</ListItem>
              </ListItemWrapper>
              <ListItemWrapper>
                <ListItem>{t(`smartCity`)}</ListItem>
              </ListItemWrapper>
            </tbody>
          </List>
          <Description
            dangerouslySetInnerHTML={{
              __html: fixConjunctions(t(`history_others`)),
            }}
          />
        </MainWrapper>
        <DescriptionWrapper>
          <Column>
            <HorizontalDivider />
            <Description>
              <DescriptionTitle>
                {t(`history_academicCollaboration_title`)}
              </DescriptionTitle>
              {t(`history_academicCollaboration_description`)}
            </Description>
            <HorizontalDivider />
            <Description>
              <DescriptionTitle>
                {t(`history_businessModels_title`)}
              </DescriptionTitle>
              {t(`history_businessModels_description`)}
            </Description>
            <HorizontalDivider />
            <Description>
              <DescriptionTitle>
                {t(`history_employees_title`)}
              </DescriptionTitle>
              {t(`history_employees_description`)}
            </Description>
          </Column>
        </DescriptionWrapper>
      </PageWrapper>
    </>
  );
};

export default HistoryMobile;
