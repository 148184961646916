import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H4,
  TEXT_BODY_2,
  TEXT_BUTTON_2,
} from "../../../../../../assets/styles/typography";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

interface PageProps {
  animation?: string;
}

export const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  padding: 160px 24px 0;
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  ${H4}
  width: 100%;
  margin-bottom: 16px;
`;

interface TitleProps {
  color?: string;
}

export const Title = styled.h2`
  ${H4}
  margin: 0;
  padding: 0;
  color: ${(props: TitleProps) => props.color || "#151515"};
  text-transform: uppercase;
  white-space: pre-line;
`;

export const MapWrapper = styled.div`
  position: absolute;
  top: 600px;
  left: -90%;
  width: 200%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  transform: scaleY(1);
`;

export const List = styled.table`
  width: 100%;
  outline: none;
  border: none;
  margin: 32px 0 16px 0;
`;

export const ListItemWrapper = styled.tr`
  list-style: none;
`;

export const ListItem = styled.td`
  ${TEXT_BUTTON_2}
  padding: 0;
  color: #151515;
  text-transform: uppercase;
  border: none;
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 500px 24px 88px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  width: 100%;
  color: #151515;
  white-space: pre-line;
`;

export const DescriptionTitle = styled.div`
  ${TEXT_BUTTON_2}
  color: #151515;
  white-space: pre-line;
  margin-bottom: 8px;
`;

export const HorizontalDivider = styled.hr`
  width: 100%;
  min-height: 1px;
  margin: 24px 0;
  background: #eaeaea;
`;
